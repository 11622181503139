


























































@import "../../assets/css/login";

.start {
  padding-top: 10%;

  h3 {
    margin-top: 10px;
  }
}
